import Axios from 'axios'
import { MenuSyncError } from "./Errors"

const TOKEN_KEY = 'anita-token'

const getToken = () => localStorage.getItem(TOKEN_KEY)
const setToken = token => localStorage.setItem(TOKEN_KEY, token)
const deleteToken = () => localStorage.removeItem(TOKEN_KEY)

const isDev = process.env.NODE_ENV === "development"
const ENDPOINT_HOST = isDev ? 'http://localhost:5643' : 'https://api.anita-delivery.com'

const endpoint = Axios.create({
  baseURL: ENDPOINT_HOST,
  headers: { 'X-Anita-Platform': 4 }
})

class ParameterError extends Error {
  constructor(parameters) {
    super()
    this.parameters = parameters
  }
}

const Account = {
  requestPasswordReset: async (email) => {
    await endpoint.post(
      '/accounts/request-password-reset',
      { email }
    )
  },
  retrieve: async () => {
    let response

    try {
      response = await endpoint.get(
        '/accounts/me',
        { headers: { 'Authorization': `Bearer ${getToken()}` } }
      )
    } catch (error) {
      console.log(error)
      return null
    }

    if (response.data.errors != null) {
      console.log(response.data.errors)
      return null
    }

    return response.data.data.account
  },
  create: async (email, password, firstName, lastName, phoneNumber) => {
    let response

    try {
      response = await endpoint.post(
        '/accounts/',
        { email, password, firstName, lastName, phoneNumber }
      )
    } catch (error) {
      if (error.response != null) {
        console.log(`Server error`, error.message, error.response.data)
      } else if (error.request != null) {
        console.log(`Request error`, error.message, error.request)
      } else {
        console.log(`Unknown error`, error.message)
      }

      return null
    }

    if (response.data.errors != null) {
      console.log(`User error`, response.data.errors)

      const parameters = response.data.errors.reduce(
        (root, error) => {
          root[error.param] = error.msg
          return root
        },
        {}
      )

      throw new ParameterError(parameters)
    }

    const { account, token } = response.data.data
    setToken(token)

    return account
  },
  logOut: async () => {
    try {
      await endpoint.post(
        '/accounts/log-out',
        null,
        { headers: { 'Authorization': `Bearer ${getToken()}` } }
      )
    } catch (err) {
      console.log(`whoopsie logging out`, err)
    }

    deleteToken()
  },
  logIn: async (email, password) => {
    let response

    try {
      response = await endpoint.post(
        '/accounts/log-in',
        { email, password }
      )
    } catch (error) {
      if (error.response != null) {
        console.log(`Server error`, error.message, error.response.data)
      } else if (error.request != null) {
        console.log(`Request error`, error.message, error.request)
      } else {
        console.log(`Unknown error`, error.message)
      }

      return null
    }

    if (response.data.errors != null) {
      console.log(`User error`, response.data.errors)

      const parameters = response.data.errors.reduce(
        (root, error) => {
          root[error.param] = error.msg
          return root
        },
        {}
      )

      throw new ParameterError(parameters)
    }

    if(response.data.data.account.isAdmin === false)
    {
      console.log('only admins can login to this service.');

      return null;
    }

    const { account, token } = response.data.data
    setToken(token)

    return account
  }
}

const Merchant = {
  register: async (businessName) => {
    let response

    try {
      response = await endpoint.post(
        '/merchants/',
        { businessName },
        { headers: { 'Authorization': `Bearer ${getToken()}` } }
      )
    } catch (error) {
      console.log(`it dun borked`, error)
      return null
    }

    if (response.data.errors != null) {
      const parameters = response.data.errors.reduce(
        (root, error) => {
          root[error.param] = error.msg
          return root
        },
        {}
      )

      throw new ParameterError(parameters)
    }

    return response.data.data.account
  },
  getPremises: async () => {
    let response

    try {
      response = await endpoint.get(
        '/merchants/premises',
        { headers: { 'Authorization': `Bearer ${getToken()}` } }
      )
    } catch (error) {
      console.log('???', error)
      return null
    }

    return response.data.data.premises
  },
  addPremises: async (name, streetAddress, city, postcode, phoneNumber) => {
    let response

    try {
      response = await endpoint.post(
        '/merchants/premises',
        { name, streetAddress, city, postcode, phoneNumber },
        { headers: { 'Authorization': `Bearer ${getToken()}` } }
      )

      console.log(response)
    } catch (error) {
      console.log(`it dun borked`, error)
      return null
    }

    if (response.data.errors != null) {
      console.log(response.data.errors)

      const parameters = response.data.errors.reduce(
        (root, error) => {
          root[error.param] = error.msg
          return root
        },
        {}
      )

      throw new ParameterError(parameters)
    }

    return response.data.data.premises
  },
  connectStripe: async (code) => {
    let response

    try {
      response = await endpoint.post(
        '/merchants/connect-stripe',
        { code },
        { headers: { 'Authorization': `Bearer ${getToken()}` } }
      )
    } catch (error) {
      console.log('connectStripe error', error)
      return false
    }

    if (response.data.errors != null) {
      console.log(response.data.errors)
      return false
    }

    return true
  },
  getOrderHistoryForPremises: async (id, page) => {
    let response

    try {
      response = await endpoint.get(
        `/merchants/premises/${id}/order-history?page=${page}`,
        { headers: { 'Authorization': `Bearer ${getToken()}` } }
      )
    } catch (error) {
      console.log(error)
      return null
    }

    if (response.data.errors != null) {
      console.log(response.data.errors)
      return null
    }

    return response.data.data.history
  },
  isLatestMenu: async (premisesID, hash) => {
    const response = await endpoint.get(
      `/merchants/premises/${premisesID}/menu-check?hash=${hash}`,
      { headers: { "Authorization": `Bearer ${getToken()}` } }
    )

    return response.data.data.isLatest
  },
  getMenu: async (premisesID) => {
    let response

    try {
      response = await endpoint.get(
        `/merchants/premises/${premisesID}/menu`,
        { headers: { "Authorization": `Bearer ${getToken()}` } }
      )
    } catch (error) {
      console.log("Error getting menu for premises", premisesID, error)
      return null
    }

    return response.data.data
  },
  updateMenu: async (premisesID, menu, currentHash) => {
    let response

    try {
      response = await endpoint.post(
        `/merchants/premises/${premisesID}/menu`,
        { menu, currentHash },
        { headers: { "Authorization": `Bearer ${getToken()}` } }
      )
    } catch (error) {
      if (error.response && error.response.status === 409) {
        throw new MenuSyncError("Please reload and try again.")
      }

      console.log("Error updating menu for premises", premisesID, error)
      throw new Error("Network error. Please check your internet connection and try again.")
    }

    if (response.data.errors != null) {
      console.log(response.data.errors)
      return null
    }

    return response.data.data.hash
  },
  uploadPhoto: async (premisesID, image) => {
    const formData = new FormData()
    formData.append("image", image)

    let response

    try {
      response = await endpoint.post(
        `/merchants/premises/${premisesID}/upload`,
        formData,
        {
          headers: {
            "Authorization": `Bearer ${getToken()}`,
            "Content-Type": "multipart/form-data"
          }
        }
      )
    } catch (error) {
      alert(error.message)
      console.log("Error uploading photo for premises", premisesID, error)
      return null
    }

    if (response.data.errors != null) {
      console.log(response.data.errors)
      return false
    }

    return response.data.data.filename
  }
}

const Drivers = {
  getDrivers: async () => {
    let response

    try {
      response = await endpoint.get(
          '/drivers/approved-drivers',
          { headers: { 'Authorization': `Bearer ${getToken()}` } }
      )
    } catch (error) {
      console.log('???', error)
      return null
    }

    return response.data.data.drivers
  },
  getDriverAreas: async () => {
    let response

    try {
      response = await endpoint.get(
          '/drivers/driver-areas',
          { headers: { 'Authorization': `Bearer ${getToken()}` } }
      )
    } catch (error) {
      console.log('???', error)
      return null
    }

    return response.data.data.areas
  },
  updateDriverArea: async (driver, area, checked) => {
    let response
    try {
      response = await endpoint.post(
          '/drivers/update-driver-area',
          { driver, area, checked },
          { headers: { 'Authorization': `Bearer ${getToken()}` } }
      )

      console.log(response)
    } catch (error) {
      console.log(`it dun borked`, error)
      return null
    }

    if (response.data.errors != null) {
      console.log(response.data.errors)

      const parameters = response.data.errors.reduce(
          (root, error) => {
            root[error.param] = error.msg
            return root
          },
          {}
      )

      throw new ParameterError(parameters)
    }

    return response.data.data.premises
  },
}

const isAuthenticated = () => getToken() != null

export default {
  Account,
  Merchant,
  Drivers,
  isAuthenticated
}
