import React, { useContext } from "react"
import Styled from "styled-components"

import AppContext from "../contexts/app"

export default function() {
  const context = useContext(AppContext)

  return (
    <Container>
      {
        context.account
          ? (
            <>
              <h1>Anita Delivery</h1>
              <p>Manage driver delivery areas by clicking the buttons on the top right.</p>
            </>
          ) : (
            <>
              <h1>Welcome to Anita</h1>
              <h3>Log in to manage driver delivery areas.</h3>
            </>
          )
      }
    </Container>
  )
}

const Container = Styled.div`
  padding: 16px;
`
