import React from 'react'
import AppContext from '../contexts/app'

import AnitaAPI from "../lib/AnitaAPI"

import {
  Button
} from '../components/Form'

export default class Account extends React.Component {
  static contextType = AppContext

  render() {
    const { account } = this.context

    if (account == null) {
      return null
    }

    return (
      <div style={{ flex: 1, padding: 16, flexDirection: "column" }}>
        <h1>Your account</h1>
        <br />
        <Button
          style={{ marginTop: 64 }}
          onClick={async () => {
            await AnitaAPI.Account.logOut()
            this.context.set({ account: null })

            this.props.history.replace("/")
          }}
        >
          Log out
        </Button>
      </div>
    )
  }
}

