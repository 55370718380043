import React from 'react'
import Styled from 'styled-components'

import AnitaAPI from '../lib/AnitaAPI'
import AppContext from '../contexts/app'

import "./ManageDrivers.css"

export default class ManageDrivers extends React.Component {
  static contextType = AppContext

  state = {
    drivers: [],
    areas: []
  }

  async componentDidMount() {
    const drivers = await AnitaAPI.Drivers.getDrivers()
    const areas = await AnitaAPI.Drivers.getDriverAreas()

    this.setState({ drivers, areas })
  }

  async handleChange(driver, area, checked)
  {
    await AnitaAPI.Drivers.updateDriverArea(driver.driverID, area.id, checked.toString());

    let drivers = await AnitaAPI.Drivers.getDrivers()

    this.setState({ drivers })
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Container>
          <h1>Your Drivers</h1>
          { this.state.drivers.length === 0 &&
            <>
              <p>You haven't added any drivers yet.</p>
            </>
          }
          { this.state.drivers.length > 0 &&
            <PremisesList>
              {this.state.drivers.map((driver) => (
                <div
                  key={driver.driverID}
                  className="drivers"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 16,
                    border: "1px solid rgba(0, 0, 0, 0.2)",
                    borderRadius: 4,
                    cursor: "pointer",
                    marginBottom: 16
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "left"
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold"
                      }}
                    >
                      {driver.firstName} {driver.lastName}
                    </div>
                    <div
                      style={{
                        marginTop: 4,
                        fontSize: 14,
                        marginBottom: 8
                      }}
                    >
                      {driver.phoneNumber}
                    </div>
                    {this.state.areas.filter(area => area.isActive === true).map((area) => (
                        <div
                            key={driver.driverID + area.id}
                            style={{
                              marginTop: 4,
                              fontSize: 14,
                            }}
                        >
                         <input type="checkbox" checked={driver.areas.includes(area.id)} onChange={(event) => this.handleChange(driver, area, !driver.areas.includes(area.id))}/>{area.label}
                        </div>
                    ))}
                  </div>
                </div>
              ))}
            </PremisesList>
          }
        </Container>
      </div>
    )
  }
}

const Container = Styled.div`
  padding: 16px;
  flex: 1;
  overflow-y: auto;
`

const PremisesList = Styled.div`
  max-width: 768px;
  margin: 32px auto 32px auto;

  display: flex;
  flex-direction: column;
`
