import React from 'react'
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom'
import Styled from 'styled-components'

import Index from './pages/Index'
import LogIn from './pages/LogIn'
import NotFound from './pages/NotFound'
import ManageDrivers from './pages/ManageDrivers'
import Account from './pages/Account'
import ForgotPassword from "./pages/ForgotPassword"

import AppContext from './contexts/app'
import AnitaAPI from './lib/AnitaAPI'

// v2 stuff

import AnitaLogo from "./anita_logo_icon.png"

export default class App extends React.Component {
  constructor(props) {
    super(props)

    this.router = React.createRef()

    this.state = {
      set: this.set,
      account: null,
    }
  }

  async componentDidMount() {
    if (!AnitaAPI.isAuthenticated()) {
      return
    }

    const account = await AnitaAPI.Account.retrieve()
    this.setState({ account })

    if (account != null && account.merchant == null) {
      this.router.current.history.replace('/merchant-registration')
    }
  }

  render() {
    const isAuthenticated = AnitaAPI.isAuthenticated()

    return (
      <Router ref={this.router}>
        <AppContext.Provider value={this.state}>
          <AppContainer>
            <Switch>
              <Route
                path="/"
                component={() => {
                  return (
                    <>
                      <NavBar>
                        <Logo to='/'>
                          <img
                            src={AnitaLogo}
                            alt="Anita Delivery"
                            style={{
                              width: 24,
                              height: 24,
                              padding: 6,
                              backgroundColor: "#fff",
                              borderRadius: 32
                            }}
                          />
                        </Logo>
                        <NavMenu>
                          { !isAuthenticated &&
                            <>
                              <NavMenuSpacer />
                              <NavMenuItem to='/log-in'>
                                <span style={{ margin: "0px 6px" }}>Log in</span>
                              </NavMenuItem>
                            </>
                          }
                          { isAuthenticated &&
                            <>
                              <NavMenuSpacer />
                              <NavMenuItem to='/drivers'>
                                <i className="material-icons">store</i>
                              </NavMenuItem>
                              <NavMenuSpacer />
                              <NavMenuItem to='/account'>
                                <i className="material-icons">account_circle</i>
                              </NavMenuItem>
                            </>
                          }
                        </NavMenu>
                      </NavBar>
                      <Switch>
                        <Route exact path='/' component={Index} />
                        <Route exact path='/log-in' component={LogIn} />
                        <Route exact path='/drivers' component={ManageDrivers} />
                        <Route exact path='/account' component={Account} />
                        <Route path="/forgot-password" component={ForgotPassword} />

                        <Route component={NotFound} />
                      </Switch>
                    </>
                  )
                }}
              />
            </Switch>
          </AppContainer>
        </AppContext.Provider>
      </Router>
    )
  }

  set = data => {
    this.setState({ ...data })
  }

  logOut = async () => {
    await AnitaAPI.Account.logOut()
    this.setState({ account: null })
  }
}

const AppContainer = Styled.div`
  text-align: center;
  height: 100%;

  display: flex;
  flex-direction: column;
`

const NavBar = Styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 16px;
  border-bottom: 1px solid #fff;

  background-color: #008800;

  /* box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25); */
`

const Logo = Styled(Link)`
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
`

const NavMenu = Styled.div`
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const NavMenuItem = Styled(Link)`
  display: flex;
  padding: 6px;

  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;

  /* border: 1px solid rgba(0, 0, 0, 0); */
  border: 2px solid rgba(256, 256, 256, 0.25);
  border-radius: 32px;

  :hover {
    border: 2px solid rgba(256, 256, 256, 0.75);
  }

  :active {
    color: #008800;
    background-color: #fff;
  }

  transition: 
    background-color .15s,
    color .15s,
    border .15s;
`

const NavMenuSpacer = Styled.div` 
  width: 16px;
`
